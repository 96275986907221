@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Vollkorn";
  src: url("./fonts/Vollkorn\ Italic.woff2") format("woff2");

  font-style: normal;
}
