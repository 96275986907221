.why-us {
    margin-top: rem(-180);
    position: relative;
    overflow: hidden;

    @media (max-width: $lg) {
        margin-top: rem(-50);
    }
    @media (max-width: $lg) {
        margin-top: rem(-100);
    }
    &__svg {
        margin-top: rem(-60);
        position: relative;
        @media (max-width: $lg) {
            svg {
                transform: scale(0.7);
                transform-origin: left;
            }
        }
    }
    &__heading {
        font-weight: 600;
        color: $color-light;
        width: 100%;
        max-width: 1220px;
        margin-inline: auto;
        text-transform: uppercase;
        z-index: 3;
        position: relative;
        padding-inline: rem(25);
        margin-top: rem(20);
        @media (max-width: $lg) {
            text-align: center;
            margin-top: rem(-30);
            margin-left: -20%;
        }
        @media (max-width: $md) {
            margin-top: rem(-70);
            font-size: rem(20);
        }
        @media (max-width: $sm) {
            margin-top: rem(-90);
            margin-left: rem(-80);
        }
    }
    &__blue {
        position: relative;
        z-index: 2;
        img {
            width: 100%;
            max-height: rem(937);
        }
    }
    &__arrow {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        top: 0;
        @media (max-width: $xl) {
            top: rem(-20);
        }
        @media (max-width: $lg) {
            display: none;
        }

        span {
            display: block;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: rem(5);
            animation: arrow 3s ease-in-out infinite;
            position: relative;
        }
    }
    &__circle {
        position: absolute;
        width: rem(100);
        height: rem(100);
        background-color: $color-1;
        border-radius: 50%;
        z-index: -5;
        top: rem(20);
        left: 50%;
        transform: translateX(-50%);
        @media (max-width: $xl) {
            top: rem(0);
        }
        @media (max-width: $lg) {
            display: none;
        }
    }
    &__columns {
        display: flex;
        align-items: center;
        margin-top: rem(-160);
        gap: rem(50);
        position: relative;
        z-index: 2;
        @media (max-width: $lg) {
            flex-direction: column;
            margin-top: rem(-260);
            gap: rem(50);
        }
        @media (max-width: $md) {
            margin-top: rem(-180);
        }
    }
    &__path {
        width: 100%;
        margin-top: rem(290);
        position: relative;
        @media (max-width: $lg) {
            margin-top: rem(200);
            margin-bottom: rem(100);
        }
    }
    &__king {
        position: absolute;
        max-width: 1500px;
        margin-inline: auto;
        top: rem(-360);
    }
    &__img {
        z-index: -1;
        position: relative;
        @media (max-width: $lg) {
        }
        svg {
            @media (max-width: $lg) {
                width: rem(250);
                margin-top: 50px;
            }
            @media (max-width: $md) {
                width: rem(200);
                margin-top: rem(80);
            }
        }
    }
    &__column {
        position: relative;
    }
    &__left {
        width: 55%;
        @media (max-width: $lg) {
            width: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        &:before,
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            mask-repeat: no-repeat;
            -webkit-mask-repeat: no-repeat;
        }
        &::before {
            left: rem(50);
            bottom: rem(50);
            mask-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' width='100%25' id='blobSvg' style='opacity: 1;'%3E%3Cpath id='blob' fill='url(%23gradient)' style='opacity: 1;'%3E%3Canimate attributeName='d' dur='9000ms' repeatCount='indefinite' values='M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;'%3E%3C/animate%3E%3C/path%3E%3Cdefs%3E%3ClinearGradient id='gradient' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' style='stop-color: rgb(238, 205, 163);'%3E%3C/stop%3E%3Cstop offset='100%25' style='stop-color: rgb(239, 98, 159);'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
            -webkit-mask-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' width='100%25' id='blobSvg' style='opacity: 1;'%3E%3Cpath id='blob' fill='url(%23gradient)' style='opacity: 1;'%3E%3Canimate attributeName='d' dur='9000ms' repeatCount='indefinite' values='M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;'%3E%3C/animate%3E%3C/path%3E%3Cdefs%3E%3ClinearGradient id='gradient' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' style='stop-color: rgb(238, 205, 163);'%3E%3C/stop%3E%3Cstop offset='100%25' style='stop-color: rgb(239, 98, 159);'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
            background-color: $color-3;
            width: rem(157);
            height: rem(158);
            animation: before 10s ease-in-out infinite;

            @media (max-width: $lg) {
                left: rem(90);
                bottom: rem(100);
            }
            @media (max-width: $md) {
                left: 5%;
                bottom: 5%;
            }
        }
        &::after {
            right: rem(-32);
            top: rem(80);
            mask-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' width='100%25' id='blobSvg' style='opacity: 1;'%3E%3Cpath id='blob' fill='url(%23gradient)' style='opacity: 1;'%3E%3Canimate attributeName='d' dur='10000ms' repeatCount='indefinite' values='M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;'%3E%3C/animate%3E%3C/path%3E%3Cdefs%3E%3ClinearGradient id='gradient' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' style='stop-color: rgb(238, 205, 163);'%3E%3C/stop%3E%3Cstop offset='100%25' style='stop-color: rgb(239, 98, 159);'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
            -webkit-mask-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' width='100%25' id='blobSvg' style='opacity: 1;'%3E%3Cpath id='blob' fill='url(%23gradient)' style='opacity: 1;'%3E%3Canimate attributeName='d' dur='10000ms' repeatCount='indefinite' values='M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;'%3E%3C/animate%3E%3C/path%3E%3Cdefs%3E%3ClinearGradient id='gradient' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' style='stop-color: rgb(238, 205, 163);'%3E%3C/stop%3E%3Cstop offset='100%25' style='stop-color: rgb(239, 98, 159);'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
            background-color: $color-1;
            width: rem(303);
            height: rem(287);
            z-index: -1;
            animation: after 15s ease-in-out infinite;
        }
    }
    &__right {
        position: relative;
        text-align: justify;
        font-size: rem(22);
        font-weight: 300;
        width: 40%;
        @media (max-width: $lg) {
            width: 100%;
        }
        @media (max-width: $md) {
            font-size: rem(16);
        }
        &:after {
            content: "";
            position: absolute;
            bottom: rem(-50);
            right: rem(-30);
            mask-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' width='100%25' id='blobSvg' style='opacity: 1;'%3E%3Cpath id='blob' fill='url(%23gradient)' style='opacity: 1;'%3E%3Canimate attributeName='d' dur='8000ms' repeatCount='indefinite' values='M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;'%3E%3C/animate%3E%3C/path%3E%3Cdefs%3E%3ClinearGradient id='gradient' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' style='stop-color: rgb(238, 205, 163);'%3E%3C/stop%3E%3Cstop offset='100%25' style='stop-color: rgb(239, 98, 159);'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
            -webkit-mask-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' width='100%25' id='blobSvg' style='opacity: 1;'%3E%3Cpath id='blob' fill='url(%23gradient)' style='opacity: 1;'%3E%3Canimate attributeName='d' dur='8000ms' repeatCount='indefinite' values='M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;'%3E%3C/animate%3E%3C/path%3E%3Cdefs%3E%3ClinearGradient id='gradient' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' style='stop-color: rgb(238, 205, 163);'%3E%3C/stop%3E%3Cstop offset='100%25' style='stop-color: rgb(239, 98, 159);'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
            mask-repeat: no-repeat;
            -webkit-mask-repeat: no-repeat;
            background-color: $color-2;
            width: rem(157);
            height: rem(158);
            z-index: -1;
            animation: after 5s ease-in-out infinite;
        }
    }
}

.why-us-grid {
    padding-top: rem(100);
    max-width: rem(1540);
    margin-inline: auto;

    h3 {
        width: 100%;
        text-align: right;
        padding-inline: rem(25);
        color: $color-1;
        z-index: 2;
        position: relative;
    }
    &__wrapper {
        background-color: #ddd;
        border-radius: 15px;
        position: relative;
        margin-top: rem(-30);
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: center;
        align-items: stretch;
        padding: rem(100);
        padding-bottom: rem(220);
        gap: rem(50);
        @media (max-width: $xl) {
            gap: rem(20);
        }
        @media (max-width: $lg) {
            grid-template-columns: repeat(2, 1fr);
            padding-inline: rem(25);
            gap: rem(50);
        }
        @media (max-width: $md) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &__element {
        border-radius: 37px;
        padding-top: rem(20);
        padding-bottom: rem(20);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: rem(20);
        padding-inline: rem(25);
        text-align: justify;
        font-weight: 400;
        &:nth-child(2),
        &:nth-child(4) {
            background-color: $color-light;
        }
        &:nth-child(5),
        &:nth-child(7) {
            background-color: $color-1;
        }
        &:nth-child(3) {
            background-color: $color-2;
            color: $color-light;
        }
        &:nth-child(6) {
            background-color: $color-4;
            color: $color-light;
        }
        @media (max-width: $lg) {
            width: 100%;
        }
    }
    &__cat {
        position: absolute;
        bottom: rem(10);
        right: rem(-100);
        @media (max-width: $md) {
            transform: scale(0.8);
        }
    }
}

@keyframes before {
    0% {
        transform: translate(0px, 0px);
    }
    50% {
        transform: translate(5%, -5%);
    }
    100% {
        transform: translate(0px, 0px);
    }
}

@keyframes after {
    0% {
        transform: translate(0px, 0px);
    }
    50% {
        transform: translate(-5%, -5%);
    }
    100% {
        transform: translate(0px, 0px);
    }
}

@keyframes right {
    0% {
        transform: translate(0px, 0px);
    }
    50% {
        transform: translate(-30%, 40%);
    }
    100% {
        transform: translate(0px, 0px);
    }
}

@keyframes arrow {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(13px);
    }
    100% {
        transform: translateY(0px);
    }
}
