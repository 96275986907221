* {
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
    scroll-padding-top: var(--header-height);
}
body {
    font-size: clamp(rem(16), 2vw, rem(18));
    line-height: 1.2;
    font-family: $primary-font;
    position: relative;
    min-height: 100vh;
    overflow-x: hidden;

    @media (max-width: $lg) {
        min-height: auto;
    }
}
img {
    max-width: 100%;
}

h2,
h3,
h4,
h5,
h6 {
    color: $color-2;
    text-transform: uppercase;
    font-weight: 600;
}
h1 {
    font-size: clamp(rem(32), 5vw, rem(64));
    color: $color-4;
    //text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

h2 {
    font-size: clamp(rem(30), 5vw, rem(55));
    margin-bottom: 15px;
}
h3 {
    font-size: clamp(rem(30), 5vw, rem(55));
}
a {
    text-decoration: none;
    color: inherit;
}

section {
    padding-block: 75px;
    @media (max-width: $md) {
        padding-block: 55px;
    }
}

.container {
    width: min(100%, 1225px);
    margin-inline: auto;
    padding-inline: 25px;
    @media (max-width: $md) {
        padding-inline: 15px;
    }
}
