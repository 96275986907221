.header {
    position: sticky;
    width: 100%;
    top: rem(0);
    font-size: rem(18);
    margin-top: rem(75);
    z-index: 20;
    @media (max-width: $lg) {
        top: 0;
        position: fixed;
        margin-top: 0;
        background-color: $color-light;
        box-shadow: 10px 10px 10px rgba(15, 43, 81, 1);
    }
    @media (max-width: $md) {
        padding-top: rem(20);
    }
    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-inline: auto;
        padding-inline: rem(35);
        padding-bottom: rem(35);
        max-width: rem(1000);
        font-weight: 400;
        position: relative;
        height: rem(110);
        border-radius: 50px;
        transition: max-width 0.5s ease-in-out;
        @media (max-width: $lg) {
            align-items: center;
            padding-bottom: 0;
        }
        @media (max-width: $md) {
            padding-bottom: rem(10);
            height: auto;
        }

        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: rem(120);
            width: 100%;
            z-index: -1;
            border-radius: 50px;
            background-color: $color-light;
            box-shadow: 0px 15px 10px rgba(15, 43, 81, 0.6);
            @media (max-width: $lg) {
                display: none;
            }
        }
        &--full {
            max-width: 100%;
            transition: max-width 0.5s ease-in-out;
            border-radius: 0;
            padding-top: 15px;
            &:before {
                border-radius: 0;
            }
            @media (min-width: 992px) {
                height: auto;
            }
            .header__logo {
                @media (min-width: 992px) {
                    svg {
                        width: 150px;
                        height: 53px;
                    }
                }
            }
        }
    }
    &__logo {
        position: relative;
        z-index: 20;
        @media (max-width: $lg) {
            svg {
                width: rem(180);
            }
        }
        @media (max-width: $md) {
            svg {
                width: rem(120);
                height: rem(50);
            }
        }
    }
    &__menu {
        ul {
            display: flex;
            gap: rem(50);
            text-transform: uppercase;
            li {
                position: relative;

                padding-bottom: rem(5);

                &:after {
                    content: "";
                    width: 0%;
                    height: rem(3);
                    background-color: $color-3;
                    position: absolute;
                    bottom: 0;
                    display: block;
                    transition: $transition-time;
                }
                &:hover:after {
                    width: 100%;
                }
            }
            li:last-child {
                color: $color-1;
                font-weight: 600;
            }
        }

        @media (max-width: $lg) {
            position: absolute;
            width: 100%;
            height: 100vh;
            background: $color-light;
            top: 0;
            left: 0;
            transform: translateY(-200vh);
            display: flex;
            justify-content: center;
            align-items: center;
            &.active {
                transform: translateY(0);
            }
            uL {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &__hamburger {
        display: none;
        flex-direction: column;
        justify-content: space-between;
        width: rem(30);
        height: rem(25);
        padding: 0;
        margin: 0;
        border: none;
        background-color: transparent;
        position: relative;
        z-index: 20;
        cursor: pointer;
        @media (max-width: $lg) {
            display: flex;
        }
        span {
            width: rem(28);
            height: 5px;
            background-color: $color-1;
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }
        &.active {
            span {
                &:nth-child(2) {
                    opacity: 0;
                }
                &:nth-child(1) {
                    -webkit-transform: translateY(13px) rotate(45deg);
                    -ms-transform: translateY(13px) rotate(45deg);
                    -o-transform: translateY(13px) rotate(45deg);
                    transform: translateY(13px) rotate(45deg);
                }
                &:nth-child(3) {
                    -webkit-transform: translateY(-7px) rotate(-45deg);
                    -ms-transform: translateY(-7px) rotate(-45deg);
                    -o-transform: translateY(-7px) rotate(-45deg);
                    transform: translateY(-7px) rotate(-45deg);
                }
            }
        }
    }
}
