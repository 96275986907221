@import "style/scss/reset.scss";
@import "style/scss/variables.scss";
@import "style/scss/global.scss";
@import "style/scss/fonts.scss";

/*Sections*/
@import "style/scss/sections/header.scss";
@import "style/scss/sections/hero.scss";
@import "style/scss/sections/why-us.scss";
@import "style/scss/sections/about.scss";
@import "style/scss/sections/tabs.scss";
@import "style/scss/sections/pricing.scss";
@import "style/scss/sections/form.scss";
@import "style/scss/sections/footer.scss";
@import "style/scss/sections/quote.scss";
@import "style/scss/sections/social-fixed.scss";
/*Components*/
@import "style/scss/components/btn.scss";

/*Other*/
@import "style/scss/other/sal.scss";
