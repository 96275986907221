.about {
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        gap: rem(50);
        padding-block: rem(50);
        overflow: hidden;

        @media (max-width: $lg) {
            flex-direction: column;
            padding-top: rem(0);
        }
        h2 {
            color: $color-1;
            text-align: center;
            margin-bottom: 50px;
        }
    }
    &__right {
        position: relative;
        flex-basis: 50%;

        @media (max-width: $lg) {
            margin-inline: auto;
        }
        img {
            height: 100%;
            object-fit: cover;
            border-radius: 50px;
        }
        &:after {
            content: "";
            width: 90%;
            height: 90%;
            right: rem(-20);
            bottom: rem(-20);
            background-color: $color-2;
            position: absolute;
            z-index: -1;
            border-radius: 30px;
            @media (max-width: $lg) {
                right: rem(-20);
                bottom: rem(-20);
            }
        }
        img {
            width: 100%;
        }
    }
    &__left {
        flex-basis: 50%;
        display: flex;
        flex-direction: column;

        text-align: justify;
        font-size: rem(22);
        font-weight: 400;
        @media (max-width: $md) {
            font-size: rem(16);
        }
    }
}
