.btn {
    background-color: $color-1;
    text-transform: uppercase;
    color: $color-light;
    border: none;
    font-size: rem(25);
    padding: rem(12) rem(25);
    border-radius: 30px;
    position: relative;
    transition:
        background-color 0.3s ease-in-out,
        color 0.3s ease-in-out;
    cursor: pointer;
    @media (max-width: $md) {
        font-size: rem(20);
    }
    &::after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 50%;
        background-color: red;
        opacity: 0.1;
        border-radius: 30px;
        z-index: -1;
    }
    &:hover {
        background-color: $color-2;
    }
    &__submit {
        background-color: $color-2;
        margin-inline: auto;
        font-size: rem(20);
        padding: rem(12) rem(25);
        @media (max-width: $md) {
            font-size: rem(10);
        }
        &:hover {
            background-color: $color-3;
        }
    }
}
