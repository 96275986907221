*, :before, :after {
  box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  border: 0;
  margin: 0;
  padding: 0;
}

footer, header, nav, section, main {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

.flex-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: var(--header-height);
}

body {
  min-height: 100vh;
  font-family: Poppins, sans-serif;
  font-size: clamp(1rem, 2vw, 1.125rem);
  line-height: 1.2;
  position: relative;
  overflow-x: hidden;
}

@media (max-width: 992px) {
  body {
    min-height: auto;
  }
}

img {
  max-width: 100%;
}

h2, h3, h4, h5, h6 {
  color: #02315d;
  text-transform: uppercase;
  font-weight: 600;
}

h1 {
  color: #c5c5c5;
  font-size: clamp(2rem, 5vw, 4rem);
}

h2 {
  margin-bottom: 15px;
  font-size: clamp(1.875rem, 5vw, 3.4375rem);
}

h3 {
  font-size: clamp(1.875rem, 5vw, 3.4375rem);
}

a {
  color: inherit;
  text-decoration: none;
}

section {
  padding-block: 75px;
}

@media (max-width: 768px) {
  section {
    padding-block: 55px;
  }
}

.container {
  width: min(100%, 1225px);
  margin-inline: auto;
  padding-inline: 25px;
}

@media (max-width: 768px) {
  .container {
    padding-inline: 15px;
  }
}

@font-face {
  font-family: Poppins;
  src: url("Poppins-Light.1355d88d.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url("Poppins-Regular.7fa61b9f.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url("Poppins-SemiBold.cbffc196.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Vollkorn;
  src: url("Vollkorn Italic.26581230.woff2") format("woff2");
  font-style: normal;
}

.header {
  z-index: 20;
  width: 100%;
  margin-top: 4.6875rem;
  font-size: 1.125rem;
  position: sticky;
  top: 0;
}

@media (max-width: 992px) {
  .header {
    background-color: #fff;
    margin-top: 0;
    position: fixed;
    top: 0;
    box-shadow: 10px 10px 10px #0f2b51;
  }
}

@media (max-width: 768px) {
  .header {
    padding-top: 1.25rem;
  }
}

.header__wrapper {
  padding-inline: 2.1875rem;
  border-radius: 50px;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 62.5rem;
  height: 6.875rem;
  margin-inline: auto;
  padding-bottom: 2.1875rem;
  font-weight: 400;
  transition: max-width .5s ease-in-out;
  display: flex;
  position: relative;
}

@media (max-width: 992px) {
  .header__wrapper {
    align-items: center;
    padding-bottom: 0;
  }
}

@media (max-width: 768px) {
  .header__wrapper {
    height: auto;
    padding-bottom: .625rem;
  }
}

.header__wrapper:before {
  content: "";
  z-index: -1;
  background-color: #fff;
  border-radius: 50px;
  width: 100%;
  height: 7.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: 0 15px 10px #0f2b5199;
}

@media (max-width: 992px) {
  .header__wrapper:before {
    display: none;
  }
}

.header__wrapper--full {
  border-radius: 0;
  max-width: 100%;
  padding-top: 15px;
  transition: max-width .5s ease-in-out;
}

.header__wrapper--full:before {
  border-radius: 0;
}

@media (min-width: 992px) {
  .header__wrapper--full {
    height: auto;
  }

  .header__wrapper--full .header__logo svg {
    width: 150px;
    height: 53px;
  }
}

.header__logo {
  z-index: 20;
  position: relative;
}

@media (max-width: 992px) {
  .header__logo svg {
    width: 11.25rem;
  }
}

@media (max-width: 768px) {
  .header__logo svg {
    width: 7.5rem;
    height: 3.125rem;
  }
}

.header__menu ul {
  text-transform: uppercase;
  gap: 3.125rem;
  display: flex;
}

.header__menu ul li {
  padding-bottom: .3125rem;
  position: relative;
}

.header__menu ul li:after {
  content: "";
  background-color: #fc0;
  width: 0%;
  height: .1875rem;
  transition: all .3s;
  display: block;
  position: absolute;
  bottom: 0;
}

.header__menu ul li:hover:after {
  width: 100%;
}

.header__menu ul li:last-child {
  color: #d10c15;
  font-weight: 600;
}

@media (max-width: 992px) {
  .header__menu {
    background: #fff;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-200vh);
  }

  .header__menu.active {
    transform: translateY(0);
  }

  .header__menu uL {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.header__hamburger {
  z-index: 20;
  cursor: pointer;
  background-color: #0000;
  border: none;
  flex-direction: column;
  justify-content: space-between;
  width: 1.875rem;
  height: 1.5625rem;
  margin: 0;
  padding: 0;
  display: none;
  position: relative;
}

@media (max-width: 992px) {
  .header__hamburger {
    display: flex;
  }
}

.header__hamburger span {
  -o-transition: all .3s ease-in-out;
  background-color: #d10c15;
  width: 1.75rem;
  height: 5px;
  transition: all .3s ease-in-out;
}

.header__hamburger.active span:nth-child(2) {
  opacity: 0;
}

.header__hamburger.active span:first-child {
  transform: translateY(13px)rotate(45deg);
}

.header__hamburger.active span:nth-child(3) {
  transform: translateY(-7px)rotate(-45deg);
}

.hero {
  z-index: 5;
  margin-top: 6.25rem;
  padding-block: 3.125rem;
  position: relative;
}

@media (max-width: 992px) {
  .hero {
    margin-top: 7.5rem;
  }
}

@media (max-width: 768px) {
  .hero {
    margin-top: 4.6875rem;
  }
}

.hero__container {
  flex-direction: column;
  gap: 1.875rem;
}

@media (max-width: 992px) {
  .hero__container {
    justify-content: center;
    align-items: center;
  }
}

.hero__quote {
  text-align: right;
}

@media (max-width: 992px) {
  .hero__quote {
    text-align: center;
  }
}

.hero__quote blockquote {
  letter-spacing: .5625rem;
  margin-bottom: 1.875rem;
  font-family: Vollkorn, sans-serif;
  font-size: 3.75rem;
}

@media (max-width: 768px) {
  .hero__quote blockquote {
    letter-spacing: .1875rem;
    font-size: 2.5rem;
  }
}

.hero__quote blockquote span {
  color: #02315d;
}

.hero__quote h1 {
  color: #d10c15;
  text-transform: uppercase;
  margin-right: 1.25rem;
  font-family: Poppins, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .hero__quote h1 {
    font-size: 1.125rem;
  }
}

.hero__btns {
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  max-width: 54.375rem;
  display: flex;
}

@media (max-width: 992px) {
  .hero__btns {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.why-us {
  margin-top: -11.25rem;
  position: relative;
  overflow: hidden;
}

@media (max-width: 992px) {
  .why-us {
    margin-top: -6.25rem;
  }
}

.why-us__svg {
  margin-top: -3.75rem;
  position: relative;
}

@media (max-width: 992px) {
  .why-us__svg svg {
    transform-origin: 0;
    transform: scale(.7);
  }
}

.why-us__heading {
  color: #fff;
  text-transform: uppercase;
  z-index: 3;
  margin-inline: auto;
  width: 100%;
  max-width: 1220px;
  margin-top: 1.25rem;
  padding-inline: 1.5625rem;
  font-weight: 600;
  position: relative;
}

@media (max-width: 992px) {
  .why-us__heading {
    text-align: center;
    margin-top: -1.875rem;
    margin-left: -20%;
  }
}

@media (max-width: 768px) {
  .why-us__heading {
    margin-top: -4.375rem;
    font-size: 1.25rem;
  }
}

@media (max-width: 576px) {
  .why-us__heading {
    margin-top: -5.625rem;
    margin-left: -5rem;
  }
}

.why-us__blue {
  z-index: 2;
  position: relative;
}

.why-us__blue img {
  width: 100%;
  max-height: 58.5625rem;
}

.why-us__arrow {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1200px) {
  .why-us__arrow {
    top: -1.25rem;
  }
}

@media (max-width: 992px) {
  .why-us__arrow {
    display: none;
  }
}

.why-us__arrow span {
  justify-content: center;
  align-items: center;
  margin-top: .3125rem;
  animation: 3s ease-in-out infinite arrow;
  display: flex;
  position: relative;
}

.why-us__circle {
  z-index: -5;
  background-color: #d10c15;
  border-radius: 50%;
  width: 6.25rem;
  height: 6.25rem;
  position: absolute;
  top: 1.25rem;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1200px) {
  .why-us__circle {
    top: 0;
  }
}

@media (max-width: 992px) {
  .why-us__circle {
    display: none;
  }
}

.why-us__columns {
  z-index: 2;
  align-items: center;
  gap: 3.125rem;
  margin-top: -10rem;
  display: flex;
  position: relative;
}

@media (max-width: 992px) {
  .why-us__columns {
    flex-direction: column;
    gap: 3.125rem;
    margin-top: -16.25rem;
  }
}

@media (max-width: 768px) {
  .why-us__columns {
    margin-top: -11.25rem;
  }
}

.why-us__path {
  width: 100%;
  margin-top: 18.125rem;
  position: relative;
}

@media (max-width: 992px) {
  .why-us__path {
    margin-top: 12.5rem;
    margin-bottom: 6.25rem;
  }
}

.why-us__king {
  max-width: 1500px;
  margin-inline: auto;
  position: absolute;
  top: -22.5rem;
}

.why-us__img {
  z-index: -1;
  position: relative;
}

@media (max-width: 992px) {
  .why-us__img svg {
    width: 15.625rem;
    margin-top: 50px;
  }
}

@media (max-width: 768px) {
  .why-us__img svg {
    width: 12.5rem;
    margin-top: 5rem;
  }
}

.why-us__column {
  position: relative;
}

.why-us__left {
  width: 55%;
}

@media (max-width: 992px) {
  .why-us__left {
    width: 100%;
  }
}

.why-us__left img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.why-us__left:before, .why-us__left:after {
  content: "";
  position: absolute;
  bottom: 0;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.why-us__left:before {
  background-color: #fc0;
  width: 9.8125rem;
  height: 9.875rem;
  animation: 10s ease-in-out infinite before;
  bottom: 3.125rem;
  left: 3.125rem;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' width='100%25' id='blobSvg' style='opacity: 1;'%3E%3Cpath id='blob' fill='url(%23gradient)' style='opacity: 1;'%3E%3Canimate attributeName='d' dur='9000ms' repeatCount='indefinite' values='M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;'%3E%3C/animate%3E%3C/path%3E%3Cdefs%3E%3ClinearGradient id='gradient' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' style='stop-color: rgb(238, 205, 163);'%3E%3C/stop%3E%3Cstop offset='100%25' style='stop-color: rgb(239, 98, 159);'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' width='100%25' id='blobSvg' style='opacity: 1;'%3E%3Cpath id='blob' fill='url(%23gradient)' style='opacity: 1;'%3E%3Canimate attributeName='d' dur='9000ms' repeatCount='indefinite' values='M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;'%3E%3C/animate%3E%3C/path%3E%3Cdefs%3E%3ClinearGradient id='gradient' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' style='stop-color: rgb(238, 205, 163);'%3E%3C/stop%3E%3Cstop offset='100%25' style='stop-color: rgb(239, 98, 159);'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

@media (max-width: 992px) {
  .why-us__left:before {
    bottom: 6.25rem;
    left: 5.625rem;
  }
}

@media (max-width: 768px) {
  .why-us__left:before {
    bottom: 5%;
    left: 5%;
  }
}

.why-us__left:after {
  z-index: -1;
  background-color: #d10c15;
  width: 18.9375rem;
  height: 17.9375rem;
  animation: 15s ease-in-out infinite after;
  top: 5rem;
  right: -2rem;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' width='100%25' id='blobSvg' style='opacity: 1;'%3E%3Cpath id='blob' fill='url(%23gradient)' style='opacity: 1;'%3E%3Canimate attributeName='d' dur='10000ms' repeatCount='indefinite' values='M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;'%3E%3C/animate%3E%3C/path%3E%3Cdefs%3E%3ClinearGradient id='gradient' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' style='stop-color: rgb(238, 205, 163);'%3E%3C/stop%3E%3Cstop offset='100%25' style='stop-color: rgb(239, 98, 159);'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' width='100%25' id='blobSvg' style='opacity: 1;'%3E%3Cpath id='blob' fill='url(%23gradient)' style='opacity: 1;'%3E%3Canimate attributeName='d' dur='10000ms' repeatCount='indefinite' values='M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;'%3E%3C/animate%3E%3C/path%3E%3Cdefs%3E%3ClinearGradient id='gradient' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' style='stop-color: rgb(238, 205, 163);'%3E%3C/stop%3E%3Cstop offset='100%25' style='stop-color: rgb(239, 98, 159);'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

.why-us__right {
  text-align: justify;
  width: 40%;
  font-size: 1.375rem;
  font-weight: 300;
  position: relative;
}

@media (max-width: 992px) {
  .why-us__right {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .why-us__right {
    font-size: 1rem;
  }
}

.why-us__right:after {
  content: "";
  z-index: -1;
  background-color: #02315d;
  width: 9.8125rem;
  height: 9.875rem;
  animation: 5s ease-in-out infinite after;
  position: absolute;
  bottom: -3.125rem;
  right: -1.875rem;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' width='100%25' id='blobSvg' style='opacity: 1;'%3E%3Cpath id='blob' fill='url(%23gradient)' style='opacity: 1;'%3E%3Canimate attributeName='d' dur='8000ms' repeatCount='indefinite' values='M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;'%3E%3C/animate%3E%3C/path%3E%3Cdefs%3E%3ClinearGradient id='gradient' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' style='stop-color: rgb(238, 205, 163);'%3E%3C/stop%3E%3Cstop offset='100%25' style='stop-color: rgb(239, 98, 159);'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' width='100%25' id='blobSvg' style='opacity: 1;'%3E%3Cpath id='blob' fill='url(%23gradient)' style='opacity: 1;'%3E%3Canimate attributeName='d' dur='8000ms' repeatCount='indefinite' values='M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;'%3E%3C/animate%3E%3C/path%3E%3Cdefs%3E%3ClinearGradient id='gradient' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' style='stop-color: rgb(238, 205, 163);'%3E%3C/stop%3E%3Cstop offset='100%25' style='stop-color: rgb(239, 98, 159);'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.why-us-grid {
  max-width: 96.25rem;
  margin-inline: auto;
  padding-top: 6.25rem;
}

.why-us-grid h3 {
  text-align: right;
  color: #d10c15;
  z-index: 2;
  width: 100%;
  padding-inline: 1.5625rem;
  position: relative;
}

.why-us-grid__wrapper {
  padding: 6.25rem;
  background-color: #ddd;
  border-radius: 15px;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: stretch;
  gap: 3.125rem;
  margin-top: -1.875rem;
  padding-bottom: 13.75rem;
  display: grid;
  position: relative;
}

@media (max-width: 1200px) {
  .why-us-grid__wrapper {
    gap: 1.25rem;
  }
}

@media (max-width: 992px) {
  .why-us-grid__wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 3.125rem;
    padding-inline: 1.5625rem;
  }
}

@media (max-width: 768px) {
  .why-us-grid__wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

.why-us-grid__element {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  text-align: justify;
  border-radius: 37px;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  padding-inline: 1.5625rem;
  font-weight: 400;
  display: flex;
}

.why-us-grid__element:nth-child(2), .why-us-grid__element:nth-child(4) {
  background-color: #fff;
}

.why-us-grid__element:nth-child(5), .why-us-grid__element:nth-child(7) {
  background-color: #d10c15;
}

.why-us-grid__element:nth-child(3) {
  color: #fff;
  background-color: #02315d;
}

.why-us-grid__element:nth-child(6) {
  color: #fff;
  background-color: #c5c5c5;
}

@media (max-width: 992px) {
  .why-us-grid__element {
    width: 100%;
  }
}

.why-us-grid__cat {
  position: absolute;
  bottom: .625rem;
  right: -6.25rem;
}

@media (max-width: 768px) {
  .why-us-grid__cat {
    transform: scale(.8);
  }
}

@keyframes before {
  0% {
    transform: translate(0);
  }

  50% {
    transform: translate(5%, -5%);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes after {
  0% {
    transform: translate(0);
  }

  50% {
    transform: translate(-5%, -5%);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes right {
  0% {
    transform: translate(0);
  }

  50% {
    transform: translate(-30%, 40%);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes arrow {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(13px);
  }

  100% {
    transform: translateY(0);
  }
}

.about__container {
  justify-content: space-between;
  align-items: stretch;
  gap: 3.125rem;
  padding-block: 3.125rem;
  display: flex;
  overflow: hidden;
}

@media (max-width: 992px) {
  .about__container {
    flex-direction: column;
    padding-top: 0;
  }
}

.about__container h2 {
  color: #d10c15;
  text-align: center;
  margin-bottom: 50px;
}

.about__right {
  flex-basis: 50%;
  position: relative;
}

@media (max-width: 992px) {
  .about__right {
    margin-inline: auto;
  }
}

.about__right img {
  object-fit: cover;
  border-radius: 50px;
  height: 100%;
}

.about__right:after {
  content: "";
  z-index: -1;
  background-color: #02315d;
  border-radius: 30px;
  width: 90%;
  height: 90%;
  position: absolute;
  bottom: -1.25rem;
  right: -1.25rem;
}

@media (max-width: 992px) {
  .about__right:after {
    bottom: -1.25rem;
    right: -1.25rem;
  }
}

.about__right img {
  width: 100%;
}

.about__left {
  text-align: justify;
  flex-direction: column;
  flex-basis: 50%;
  font-size: 1.375rem;
  font-weight: 400;
  display: flex;
}

@media (max-width: 768px) {
  .about__left {
    font-size: 1rem;
  }
}

.tabs__container {
  position: relative;
}

.tabs__tab {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #d10c15;
  border-radius: 12px 12px 0 0;
  margin-bottom: 0;
  padding: .75rem 1.5625rem;
  font-size: 1.5625rem;
  display: inline-block;
}

.tabs__tab:first-child {
  background-color: #02315d;
}

.tabs__tab:nth-child(2) {
  color: #000;
  background-color: #c5c5c5;
}

.tabs__tab:nth-child(3) {
  background-color: #d10c15;
}

.tabs__tab:nth-child(4) {
  background-color: #02315d;
}

@media (max-width: 1200px) {
  .tabs__tab {
    font-size: 1.25rem;
  }
}

@media (max-width: 992px) {
  .tabs__tab {
    padding: .75rem .9375rem;
    font-size: .9375rem;
  }
}

@media (max-width: 768px) {
  .tabs__tab {
    padding: .75rem .9375rem;
    font-size: .75rem;
  }
}

@media (max-width: 576px) {
  .tabs__tab {
    padding: .75rem .625rem;
    font-size: .5rem;
  }
}

.tabs__content {
  color: #fff;
  -webkit-clip-path: inset(0 -100vmax);
  clip-path: inset(0 -100vmax);
  opacity: 0;
  height: 0;
  margin: 0;
  transition: opacity 1s ease-in-out;
  overflow: hidden;
  box-shadow: 0 0 0 100vmax #d10c15;
}

.tabs__content--active {
  opacity: 1;
  height: 25rem;
  transition: opacity 1s ease-in-out;
}

@media (max-width: 992px) {
  .tabs__content--active {
    min-width: none;
    height: auto;
    min-height: 300px;
    padding-bottom: 1.25rem;
    font-size: .875rem;
  }
}

.tabs__content h3, .tabs__content p {
  color: #fff;
  max-width: 70%;
  margin-top: 3.125rem;
  font-weight: 300;
}

.tabs__content p {
  text-align: justify;
}

@media (max-width: 576px) {
  .tabs__content p {
    max-width: 100%;
    margin-top: 1.25rem;
    padding-bottom: 9.375rem;
  }
}

.tabs__content h3 {
  font-size: 1.5625rem;
}

@media (max-width: 768px) {
  .tabs__content h3 {
    margin-top: 1.5625rem;
    font-size: 1.125rem;
  }
}

.tabs__content:nth-child(7) {
  -webkit-clip-path: inset(0 -100vmax);
  clip-path: inset(0 -100vmax);
  background-color: #d10c15;
}

.tabs__content:nth-child(5), .tabs__content:nth-child(8) {
  color: #fff;
  -webkit-clip-path: inset(0 -100vmax);
  clip-path: inset(0 -100vmax);
  background-color: #02315d;
  box-shadow: 0 0 0 100vmax #02315d;
}

.tabs__content:nth-child(6) {
  color: #000;
  -webkit-clip-path: inset(0 -100vmax);
  clip-path: inset(0 -100vmax);
  background-color: #c5c5c5;
  box-shadow: 0 0 0 100vmax #c5c5c5;
}

.tabs__content:nth-child(6) h3, .tabs__content:nth-child(6) p {
  color: #000;
}

.tabs__svg {
  z-index: 2;
  position: absolute;
  right: 0;
}

@media (max-width: 768px) {
  .tabs__svg {
    display: flex;
    bottom: 0;
  }

  .tabs__svg svg {
    width: 200px;
    height: 260px;
  }
}

@media (max-width: 576px) {
  .tabs__svg svg {
    width: 100px;
    height: 160px;
  }
}

.tabs input {
  display: none;
}

.pricing h2 {
  color: #d10c15;
}

.pricing__wrapper {
  color: #fff;
  background-color: #d10c15;
  border-radius: 50px 0;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  overflow: hidden;
}

@media (max-width: 992px) {
  .pricing__wrapper {
    flex-direction: column;
  }
}

.pricing__left {
  align-items: center;
  min-width: 700px;
  padding: 3.125rem 1.875rem 3.125rem 6.25rem;
  display: flex;
  position: relative;
}

@media (max-width: 992px) {
  .pricing__left {
    padding-inline: 1.5625rem;
    min-width: 0;
    padding: 1.875rem 1.5625rem;
  }
}

.pricing__left:before {
  content: "";
  z-index: 0;
  background-color: #02315d;
  border-radius: 0 0 50px;
  width: 350px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 992px) {
  .pricing__left:before {
    display: none;
  }
}

.pricing__left ul {
  z-index: 2;
  font-size: 1.25rem;
  position: relative;
}

@media (max-width: 768px) {
  .pricing__left ul {
    font-size: .9375rem;
  }
}

.pricing__left ul span {
  text-transform: uppercase;
  width: 250px;
  margin-right: 30px;
  display: inline-block;
}

@media (max-width: 992px) {
  .pricing__left ul span {
    width: auto;
    margin-right: .625rem;
  }
}

.pricing__left ul li {
  margin-bottom: 1.875rem;
}

.pricing__left ul strong {
  font-weight: bold;
}

.pricing__right {
  padding: 30px;
  font-weight: 300;
}

@media (max-width: 992px) {
  .pricing__right {
    background-color: #02315d;
  }
}

.pricing__right p {
  margin-bottom: 1.25rem;
}

.contact-form {
  padding-block: 9.375rem;
  position: relative;
}

@media (max-width: 992px) {
  .contact-form {
    padding-inline: .9375rem;
    padding-top: 5rem;
  }
}

@media (max-width: 768px) {
  .contact-form {
    padding-inline: .9375rem;
    padding-top: 1.875rem;
  }
}

.contact-form h2 {
  text-align: center;
  color: #000;
  margin-bottom: 3.125rem;
}

@media (max-width: 768px) {
  .contact-form h2 {
    margin-bottom: 1.25rem;
  }
}

.contact-form__container {
  color: #fff;
  background-color: #d10c15;
  border-radius: 50px;
  width: 37.5rem;
  margin-inline: auto;
  padding: 1.875rem;
}

@media (max-width: 992px) {
  .contact-form__container {
    width: 100%;
  }
}

.contact-form__big-ben {
  position: absolute;
  bottom: -7.8125rem;
  left: calc(50% - 600px);
  transform: scale(.7)translateX(-50%)translateY(100px);
}

@media (max-width: 1200px) {
  .contact-form__big-ben {
    left: calc(50% - 500px);
  }
}

@media (max-width: 992px) {
  .contact-form__big-ben {
    display: none;
  }
}

.contact-form__car {
  position: absolute;
  bottom: -.625rem;
  right: calc(50% - 500px);
}

@media (max-width: 992px) {
  .contact-form__car {
    right: 50%;
    transform: translateX(50%);
  }
}

.form {
  flex-direction: column;
  display: flex;
}

.form-control {
  border-bottom: 1px solid var(--color-grays);
  height: 3rem;
  margin-bottom: 2rem;
  position: relative;
}

.form-label {
  color: var(--color-grays);
  text-transform: uppercase;
  width: 100%;
  font-family: inherit;
  font-size: .93rem;
  font-weight: 400;
  line-height: 1.5;
  transition: all .2s;
  position: absolute;
  top: .5rem;
}

.form-label span {
  font-size: .625rem;
}

.form-input {
  z-index: 1;
  resize: none;
  color: var(--color-black);
  background: none;
  border: none;
  border-bottom: .1875rem solid #fff;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 1rem 1rem 1rem 0;
  transition: all .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.form-input::placeholder {
  opacity: 0;
  visibility: hidden;
  color: #0000;
}

.form-input:focus ~ .form-label, .form-input:not(:placeholder-shown).form-input:not(:focus) ~ .form-label {
  z-index: 9;
  font-size: .875rem;
  transition: all .2s;
  top: -.75rem;
  left: 0;
}

.form-areas {
  resize: none;
  max-width: 100%;
  min-height: 7rem;
  max-height: 16rem;
}

.contact-column {
  width: 100%;
  max-width: 30rem;
  height: auto;
  margin: 0 auto;
}

.contact-inform {
  color: var(--color-black);
  background: var(--color-white);
  box-shadow: var(--shadow-large);
  border-radius: .25rem;
  padding: 4rem 2rem 2rem;
}

.error-message {
  color: #000;
  font-size: .625rem;
  position: absolute;
  bottom: -1.0625rem;
}

.after-send {
  text-align: center;
  width: 100%;
  margin-inline: auto;
}

textarea {
  font-family: Poppins, sans-serif;
}

.footer {
  color: #fff;
  background-color: #02315d;
  gap: 1.875rem;
}

.footer__container {
  justify-content: space-between;
  gap: 3.125rem;
  padding: 1.875rem;
  display: flex;
}

@media (max-width: 992px) {
  .footer__container {
    flex-direction: column;
  }
}

.footer h2 {
  color: #fff;
  border-bottom: 1px solid #fff;
  padding-bottom: 1.875rem;
}

.footer__col {
  width: 33%;
}

@media (max-width: 992px) {
  .footer__col {
    width: 100%;
  }

  .footer__col:first-child {
    text-align: center;
  }
}

.footer__col:nth-child(3) {
  flex-wrap: wrap;
  gap: .625rem;
  width: 40%;
  display: flex;
}

@media (max-width: 992px) {
  .footer__col:nth-child(3) {
    width: 100%;
  }
}

.footer__contact {
  align-items: center;
  gap: .9375rem;
  margin-block: 1.25rem;
  display: flex;
}

@media (max-width: 992px) {
  .footer__contact {
    justify-content: center;
  }
}

.footer__social-wrapper {
  align-items: center;
  gap: .9375rem;
  display: flex;
}

@media (max-width: 992px) {
  .footer__social-wrapper {
    justify-content: center;
  }
}

.footer__social-media {
  border-radius: 50%;
}

.footer__social-media a {
  margin-right: .625rem;
}

.footer__adress {
  text-align: center;
  border-radius: 30px;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1.875rem;
}

.footer__adress p:first-child {
  text-transform: uppercase;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  font-size: 1.5625rem;
}

.footer__adress--big {
  background-color: #d10c15;
}

.footer__adress--small {
  width: 48%;
}

.footer__adress--small:first-child {
  border: .125rem solid #fff;
}

@media (max-width: 768px) {
  .footer__adress--small {
    width: 100%;
  }
}

.quote {
  position: relative;
}

@media (max-width: 1200px) {
  .quote {
    margin-top: -6.25rem;
    padding-block: 0;
  }
}

.quote__container {
  color: #fff;
  justify-content: center;
  align-items: center;
  height: 23.75rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (max-width: 768px) {
  .quote__container {
    height: 12.5rem;
  }
}

.quote__wrapper {
  z-index: 3;
  text-align: right;
  visibility: hidden;
  opacity: 0;
  height: 0;
  margin-top: 130px;
  padding-inline: 1.5625rem;
  font-size: 1.25rem;
  transition: height .5s ease-in-out, opacity .5s ease-in-out;
  position: absolute;
  bottom: -1.875rem;
  overflow: hidden;
}

.quote__wrapper.active {
  visibility: visible;
  opacity: 1;
  height: 200px;
}

.quote__wrapper blockquote {
  margin-bottom: 1.875rem;
  font-size: 2.5rem;
  font-style: italic;
  font-weight: 300;
}

@media (max-width: 992px) {
  .quote__wrapper blockquote {
    font-size: 1.5625rem;
  }
}

@media (max-width: 768px) {
  .quote__wrapper blockquote {
    font-size: 1.25rem;
  }
}

.quote__wrapper p {
  font-weight: 300;
}

.quote__svg {
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (max-width: 768px) {
  .quote__svg {
    margin-top: 21.875rem;
  }
}

.quote__svg img {
  width: 100%;
  height: 411px;
}

@media (max-width: 992px) {
  .quote__svg img {
    height: 380px;
  }
}

.social-fixed {
  z-index: 50;
  position: fixed;
  bottom: 3.125rem;
  right: 0;
}

.social-fixed__element {
  padding-left: 1.25rem;
  padding-right: .625rem;
  background-color: #02315d;
  border-radius: 20px 0 0 20px;
  margin-bottom: 1.25rem;
  padding-block: .3125rem;
}

.btn {
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  background-color: #d10c15;
  border: none;
  border-radius: 30px;
  padding: .75rem 1.5625rem;
  font-size: 1.5625rem;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
  position: relative;
}

@media (max-width: 768px) {
  .btn {
    font-size: 1.25rem;
  }
}

.btn:after {
  content: "";
  opacity: .1;
  z-index: -1;
  background-color: red;
  border-radius: 30px;
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.btn:hover {
  background-color: #02315d;
}

.btn__submit {
  background-color: #02315d;
  margin-inline: auto;
  padding: .75rem 1.5625rem;
  font-size: 1.25rem;
}

@media (max-width: 768px) {
  .btn__submit {
    font-size: .625rem;
  }
}

.btn__submit:hover {
  background-color: #fc0;
}

[data-sal] {
  transition-delay: 0s;
  transition-delay: var(--sal-delay, 0s);
  transition-duration: .2s;
  transition-duration: var(--sal-duration, .2s);
  transition-timing-function: ease;
  transition-timing-function: var(--sal-easing, ease);
}

[data-sal][data-sal-duration="200"] {
  transition-duration: .2s;
}

[data-sal][data-sal-duration="250"] {
  transition-duration: .25s;
}

[data-sal][data-sal-duration="300"] {
  transition-duration: .3s;
}

[data-sal][data-sal-duration="350"] {
  transition-duration: .35s;
}

[data-sal][data-sal-duration="400"] {
  transition-duration: .4s;
}

[data-sal][data-sal-duration="450"] {
  transition-duration: .45s;
}

[data-sal][data-sal-duration="500"] {
  transition-duration: .5s;
}

[data-sal][data-sal-duration="550"] {
  transition-duration: .55s;
}

[data-sal][data-sal-duration="600"] {
  transition-duration: .6s;
}

[data-sal][data-sal-duration="650"] {
  transition-duration: .65s;
}

[data-sal][data-sal-duration="700"] {
  transition-duration: .7s;
}

[data-sal][data-sal-duration="750"] {
  transition-duration: .75s;
}

[data-sal][data-sal-duration="800"] {
  transition-duration: .8s;
}

[data-sal][data-sal-duration="850"] {
  transition-duration: .85s;
}

[data-sal][data-sal-duration="900"] {
  transition-duration: .9s;
}

[data-sal][data-sal-duration="950"] {
  transition-duration: .95s;
}

[data-sal][data-sal-duration="1000"] {
  transition-duration: 1s;
}

[data-sal][data-sal-duration="1050"] {
  transition-duration: 1.05s;
}

[data-sal][data-sal-duration="1100"] {
  transition-duration: 1.1s;
}

[data-sal][data-sal-duration="1150"] {
  transition-duration: 1.15s;
}

[data-sal][data-sal-duration="1200"] {
  transition-duration: 1.2s;
}

[data-sal][data-sal-duration="1250"] {
  transition-duration: 1.25s;
}

[data-sal][data-sal-duration="1300"] {
  transition-duration: 1.3s;
}

[data-sal][data-sal-duration="1350"] {
  transition-duration: 1.35s;
}

[data-sal][data-sal-duration="1400"] {
  transition-duration: 1.4s;
}

[data-sal][data-sal-duration="1450"] {
  transition-duration: 1.45s;
}

[data-sal][data-sal-duration="1500"] {
  transition-duration: 1.5s;
}

[data-sal][data-sal-duration="1550"] {
  transition-duration: 1.55s;
}

[data-sal][data-sal-duration="1600"] {
  transition-duration: 1.6s;
}

[data-sal][data-sal-duration="1650"] {
  transition-duration: 1.65s;
}

[data-sal][data-sal-duration="1700"] {
  transition-duration: 1.7s;
}

[data-sal][data-sal-duration="1750"] {
  transition-duration: 1.75s;
}

[data-sal][data-sal-duration="1800"] {
  transition-duration: 1.8s;
}

[data-sal][data-sal-duration="1850"] {
  transition-duration: 1.85s;
}

[data-sal][data-sal-duration="1900"] {
  transition-duration: 1.9s;
}

[data-sal][data-sal-duration="1950"] {
  transition-duration: 1.95s;
}

[data-sal][data-sal-duration="2000"] {
  transition-duration: 2s;
}

[data-sal][data-sal-delay="50"] {
  transition-delay: 50ms;
}

[data-sal][data-sal-delay="100"] {
  transition-delay: .1s;
}

[data-sal][data-sal-delay="150"] {
  transition-delay: .15s;
}

[data-sal][data-sal-delay="200"] {
  transition-delay: .2s;
}

[data-sal][data-sal-delay="250"] {
  transition-delay: .25s;
}

[data-sal][data-sal-delay="300"] {
  transition-delay: .3s;
}

[data-sal][data-sal-delay="350"] {
  transition-delay: .35s;
}

[data-sal][data-sal-delay="400"] {
  transition-delay: .4s;
}

[data-sal][data-sal-delay="450"] {
  transition-delay: .45s;
}

[data-sal][data-sal-delay="500"] {
  transition-delay: .5s;
}

[data-sal][data-sal-delay="550"] {
  transition-delay: .55s;
}

[data-sal][data-sal-delay="600"] {
  transition-delay: .6s;
}

[data-sal][data-sal-delay="650"] {
  transition-delay: .65s;
}

[data-sal][data-sal-delay="700"] {
  transition-delay: .7s;
}

[data-sal][data-sal-delay="750"] {
  transition-delay: .75s;
}

[data-sal][data-sal-delay="800"] {
  transition-delay: .8s;
}

[data-sal][data-sal-delay="850"] {
  transition-delay: .85s;
}

[data-sal][data-sal-delay="900"] {
  transition-delay: .9s;
}

[data-sal][data-sal-delay="950"] {
  transition-delay: .95s;
}

[data-sal][data-sal-delay="1000"] {
  transition-delay: 1s;
}

[data-sal][data-sal-easing="linear"] {
  transition-timing-function: linear;
}

[data-sal][data-sal-easing="ease"] {
  transition-timing-function: ease;
}

[data-sal][data-sal-easing="ease-in"] {
  transition-timing-function: ease-in;
}

[data-sal][data-sal-easing="ease-out"] {
  transition-timing-function: ease-out;
}

[data-sal][data-sal-easing="ease-in-out"] {
  transition-timing-function: ease-in-out;
}

[data-sal][data-sal-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
}

[data-sal][data-sal-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
}

[data-sal][data-sal-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(.645, .045, .355, 1);
}

[data-sal][data-sal-easing="ease-in-circ"] {
  transition-timing-function: cubic-bezier(.6, .04, .98, .335);
}

[data-sal][data-sal-easing="ease-out-circ"] {
  transition-timing-function: cubic-bezier(.075, .82, .165, 1);
}

[data-sal][data-sal-easing="ease-in-out-circ"] {
  transition-timing-function: cubic-bezier(.785, .135, .15, .86);
}

[data-sal][data-sal-easing="ease-in-expo"] {
  transition-timing-function: cubic-bezier(.95, .05, .795, .035);
}

[data-sal][data-sal-easing="ease-out-expo"] {
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
}

[data-sal][data-sal-easing="ease-in-out-expo"] {
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
}

[data-sal][data-sal-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-sal][data-sal-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-sal][data-sal-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-sal][data-sal-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(.895, .03, .685, .22);
}

[data-sal][data-sal-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(.165, .84, .44, 1);
}

[data-sal][data-sal-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(.77, 0, .175, 1);
}

[data-sal][data-sal-easing="ease-in-quint"] {
  transition-timing-function: cubic-bezier(.755, .05, .855, .06);
}

[data-sal][data-sal-easing="ease-out-quint"] {
  transition-timing-function: cubic-bezier(.23, 1, .32, 1);
}

[data-sal][data-sal-easing="ease-in-out-quint"] {
  transition-timing-function: cubic-bezier(.86, 0, .07, 1);
}

[data-sal][data-sal-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(.47, 0, .745, .715);
}

[data-sal][data-sal-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(.39, .575, .565, 1);
}

[data-sal][data-sal-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(.445, .05, .55, .95);
}

[data-sal][data-sal-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(.6, -.28, .735, .045);
}

[data-sal][data-sal-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

[data-sal][data-sal-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
}

[data-sal|="fade"] {
  opacity: 0;
  transition-property: opacity;
}

[data-sal|="fade"].sal-animate, body.sal-disabled [data-sal|="fade"] {
  opacity: 1;
}

[data-sal|="slide"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-sal="slide-up"] {
  transform: translateY(20%);
}

[data-sal="slide-down"] {
  transform: translateY(-20%);
}

[data-sal="slide-left"] {
  transform: translateX(20%);
}

[data-sal="slide-right"] {
  transform: translateX(-20%);
}

[data-sal|="slide"].sal-animate, body.sal-disabled [data-sal|="slide"] {
  opacity: 1;
  transform: none;
}

[data-sal|="zoom"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-sal="zoom-in"] {
  transform: scale(.5);
}

[data-sal="zoom-out"] {
  transform: scale(1.1);
}

[data-sal|="zoom"].sal-animate, body.sal-disabled [data-sal|="zoom"] {
  opacity: 1;
  transform: none;
}

[data-sal|="flip"] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-sal="flip-left"] {
  transform: perspective(2000px)rotateY(-91deg);
}

[data-sal="flip-right"] {
  transform: perspective(2000px)rotateY(91deg);
}

[data-sal="flip-up"] {
  transform: perspective(2000px)rotateX(-91deg);
}

[data-sal="flip-down"] {
  transform: perspective(2000px)rotateX(91deg);
}

[data-sal|="flip"].sal-animate, body.sal-disabled [data-sal|="flip"] {
  transform: none;
}

/*# sourceMappingURL=index.c35c0f61.css.map */
