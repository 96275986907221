.contact-form {
    position: relative;
    padding-block: rem(150);
    @media (max-width: $lg) {
        padding-inline: rem(15);
        padding-top: rem(80);
    }
    @media (max-width: $md) {
        padding-inline: rem(15);
        padding-top: rem(30);
    }
    h2 {
        text-align: center;
        margin-bottom: rem(50);
        color: $color-dark;
        @media (max-width: $md) {
            margin-bottom: rem(20);
        }
    }
    &__container {
        background-color: $color-1;
        border-radius: 50px;
        padding: rem(30);
        width: rem(600);
        margin-inline: auto;
        color: $color-light;
        @media (max-width: $lg) {
            width: 100%;
        }
    }
    &__big-ben {
        position: absolute;
        bottom: rem(-125);
        left: calc(50% - 600px);
        transform: scale(0.7) translateX(-50%) translateY(100px);
        @media (max-width: $xl) {
            left: calc(50% - 500px);
        }
        @media (max-width: $lg) {
            display: none;
        }
    }
    &__car {
        position: absolute;
        right: calc(50% - 500px);
        bottom: rem(-10);
        @media (max-width: $lg) {
            right: calc(50%);
            transform: translateX(50%);
        }
    }
}
.form {
    display: flex;
    flex-direction: column;
    &-control {
        position: relative;
        height: 3rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid var(--color-grays);
    }

    &-label {
        position: absolute;
        font-family: inherit;
        font-size: 0.93rem;
        font-weight: 400;
        line-height: 1.5;
        top: 0.5rem;
        width: 100%;
        color: var(--color-grays);
        transition: all 0.2s ease;
        text-transform: uppercase;
        span {
            font-size: rem(10);
        }
    }

    &-input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        padding: 1rem 1rem 1rem 0;
        resize: none;
        border: none;
        outline: none;
        color: var(--color-black);
        background: transparent;
        transition: all 0.2s ease;
        border-bottom: rem(3) solid $color-light;

        &::placeholder {
            opacity: 0;
            visibility: hidden;
            color: transparent;
        }

        &:focus ~ .form-label,
        &:not(:placeholder-shown).form-input:not(:focus) ~ .form-label {
            top: -0.75rem;
            left: 0;
            font-size: 0.875rem;
            z-index: 9;
            transition: all 0.2s ease;
        }
    }

    &-areas {
        resize: none;
        max-width: 100%;
        min-height: 7rem;
        max-height: 16rem;
    }
}

.contact {
    &-column {
        max-width: 30rem;
        width: 100%;
        height: auto;
        margin: 0 auto;
    }

    &-inform {
        padding: 4rem 2rem 2rem;
        border-radius: 0.25rem;
        color: var(--color-black);
        background: var(--color-white);
        box-shadow: var(--shadow-large);
    }
}
.error-message {
    font-size: rem(10);
    color: black;
    position: absolute;
    bottom: rem(-17);
}

.after-send {
    margin-inline: auto;
    width: 100%;
    text-align: center;
}

textarea {
    font-family: $primary-font;
}
