.quote {
    position: relative;
    @media (max-width: $xl) {
        padding-block: 0;
        margin-top: rem(-100);
    }
    &__container {
        position: relative;
        overflow: hidden;
        height: rem(380);
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-light;
        @media (max-width: $md) {
            height: rem(200);
        }
    }
    &__wrapper {
        z-index: 3;
        bottom: rem(-30);
        margin-top: 130px;
        text-align: right;
        font-size: rem(20);
        height: 0;
        overflow: hidden;
        transition:
            height 0.5s ease-in-out,
            opacity 0.5s ease-in-out;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        padding-inline: rem(25);
        &.active {
            height: 200px;
            visibility: visible;
            opacity: 1;
        }
        blockquote {
            font-size: rem(40);
            margin-bottom: rem(30);
            font-weight: 300;
            font-style: italic;
            @media (max-width: $lg) {
                font-size: rem(25);
            }
            @media (max-width: $md) {
                font-size: rem(20);
            }
        }
        p {
            font-weight: 300;
        }
    }
    &__svg {
        right: rem(0);
        bottom: 0;
        position: absolute;
        width: 100%;
        @media (max-width: $md) {
            margin-top: rem(350);
        }
        img {
            width: 100%;
            height: 411px;
            @media (max-width: $lg) {
                height: 380px;
            }
        }
    }
}
