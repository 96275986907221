.pricing {
    h2 {
        color: $color-1;
    }
    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        background-color: $color-1;
        border-radius: 50px 0 50px 0;
        overflow: hidden;
        color: $color-light;
        @media (max-width: $lg) {
            flex-direction: column;
        }
    }
    &__left {
        position: relative;
        min-width: 700px;
        padding: rem(50) rem(30);
        display: flex;
        align-items: center;
        padding-left: rem(100);
        @media (max-width: $lg) {
            padding-inline: rem(25);
            min-width: 0;
            padding: rem(30) rem(25);
        }
        &:before {
            content: "";
            width: 350px;
            background-color: $color-2;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            border-radius: 0 0 50px 0;
            @media (max-width: $lg) {
                display: none;
            }
        }
        ul {
            position: relative;
            z-index: 2;
            font-size: rem(20);
            @media (max-width: $md) {
                font-size: rem(15);
            }
            span {
                margin-right: 30px;
                width: 250px;
                display: inline-block;
                text-transform: uppercase;
                @media (max-width: $lg) {
                    width: auto;
                    margin-right: rem(10);
                }
            }
            li {
                margin-bottom: rem(30);
            }
            strong {
                font-weight: bold;
            }
        }
    }
    &__right {
        padding: 30px;
        font-weight: 300;
        @media (max-width: $lg) {
            background-color: $color-2;
        }
        p {
            margin-bottom: rem(20);
        }
    }
}
