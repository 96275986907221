.social-fixed {
    position: fixed;
    z-index: 50;
    bottom: rem(50);
    right: 0;
    &__element {
        background-color: $color-2;
        margin-bottom: rem(20);
        border-radius: 20px 0 0 20px;
        padding-left: rem(20);
        padding-right: rem(10);
        padding-block: rem(5);
    }
}
