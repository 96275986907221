.footer {
    background-color: $color-2;
    color: $color-light;

    gap: rem(30);
    &__container {
        display: flex;
        justify-content: space-between;
        gap: rem(50);
        padding: rem(30);
        @media (max-width: $lg) {
            flex-direction: column;
        }
    }
    h2 {
        color: $color-light;
        border-bottom: 1px solid $color-light;
        padding-bottom: rem(30);
    }
    &__col {
        width: 33%;
        @media (max-width: $lg) {
            width: 100%;
        }
        &:nth-child(1) {
            @media (max-width: $lg) {
                text-align: center;
            }
        }
        &:nth-child(3) {
            display: flex;
            flex-wrap: wrap;
            gap: rem(10);
            width: 40%;

            @media (max-width: $lg) {
                width: 100%;
            }
        }
    }
    &__contact {
        margin-block: rem(20);
        display: flex;
        align-items: center;
        gap: rem(15);
        @media (max-width: $lg) {
            justify-content: center;
        }
    }
    &__social-wrapper {
        display: flex;
        align-items: center;
        gap: rem(15);
        @media (max-width: $lg) {
            justify-content: center;
        }
    }
    &__social-media {
        border-radius: 50%;

        a {
            margin-right: rem(10);
        }
    }
    &__adress {
        border-radius: 30px;
        padding: rem(30);
        flex-direction: column;
        gap: rem(20);
        text-align: center;
        p:first-child {
            text-transform: uppercase;
            border-bottom: 1px solid $color-light;
            padding-bottom: 10px;
            font-size: rem(25);
        }
        &--big {
            background-color: $color-1;
        }
        &--small {
            width: 48%;
            &:nth-child(1) {
                border: rem(2) solid $color-light;
            }
            @media (max-width: $md) {
                width: 100%;
            }
        }
    }
}
