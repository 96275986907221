/*Colors*/
$color-1: #d10c15;
$color-2: #02315d;
$color-3: #ffcc00;
$color-4: #c5c5c5;
$color-5: #1a1a1a;
$color-light: #fff;
$color-dark: #000;

/*Breakpoints*/
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

/*Fonts*/
$primary-font: "Poppins", sans-serif;
$secondary-font: "Vollkorn", sans-serif;

/*Transition*/
$transition-time: 0.3s;

@function rem($sizeValue: 16) {
    @return calc($sizeValue / 16) + rem;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
