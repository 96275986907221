.hero {
    padding-block: rem(50);
    margin-top: rem(100);
    position: relative;
    z-index: 5;
    @media (max-width: $lg) {
        margin-top: rem(120);
    }
    @media (max-width: $md) {
        margin-top: rem(75);
    }
    &__container {
        flex-direction: column;
        gap: rem(30);
        @media (max-width: $lg) {
            justify-content: center;
            align-items: center;
        }
    }
    &__quote {
        text-align: right;
        @media (max-width: $lg) {
            text-align: center;
        }
        blockquote {
            font-family: $secondary-font;
            font-size: rem(60);
            margin-bottom: rem(30);
            letter-spacing: rem(9);
            @media (max-width: $md) {
                font-size: rem(40);
                letter-spacing: rem(3);
            }
            span {
                color: $color-2;
            }
        }
        h1 {
            color: $color-1;
            text-transform: uppercase;
            font-weight: 500;
            font-size: rem(24);
            font-family: $primary-font;
            margin-right: rem(20);
            @media (max-width: $md) {
                font-size: rem(18);
            }
        }
    }
    &__btns {
        display: flex;
        gap: 10px;
        width: 100%;
        justify-content: flex-end;
        max-width: rem(870);

        @media (max-width: $lg) {
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
    }
}
