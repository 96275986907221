.tabs {
    &__container {
        position: relative;
    }
    &__tab {
        margin-bottom: rem(0);
        padding: rem(12) rem(25);
        border-radius: 12px 12px 0 0;
        color: $color-light;
        text-transform: uppercase;
        font-size: rem(25);
        background-color: $color-1;
        display: inline-block;
        cursor: pointer;

        &:nth-child(1) {
            background-color: $color-2;
        }
        &:nth-child(2) {
            background-color: $color-4;
            color: $color-dark;
        }
        &:nth-child(3) {
            background-color: $color-1;
        }
        &:nth-child(4) {
            background-color: $color-2;
        }
        @media (max-width: $xl) {
            font-size: rem(20);
        }
        @media (max-width: $lg) {
            font-size: rem(15);
            padding: rem(12) rem(15);
        }
        @media (max-width: $md) {
            font-size: rem(12);
            padding: rem(12) rem(15);
        }
        @media (max-width: $sm) {
            font-size: rem(8);
            padding: rem(12) rem(10);
        }
    }
    &__content {
        color: $color-light;
        clip-path: inset(0 -100vmax);
        box-shadow: 0 0 0 100vmax $color-1;
        transition: opacity 1s ease-in-out;
        overflow: hidden;
        height: 0;
        margin: 0;
        opacity: 0;

        &--active {
            transition: opacity 1s ease-in-out;
            height: rem(400);
            opacity: 1;

            @media (max-width: $lg) {
                height: auto;
                padding-bottom: rem(20);
                font-size: rem(14);
                min-width: none;
                min-height: 300px;
            }
        }
        h3,
        p {
            margin-top: rem(50);
            color: $color-light;
            max-width: 70%;
            font-weight: 300;
        }
        p {
            text-align: justify;
            @media (max-width: $sm) {
                max-width: 100%;
                padding-bottom: rem(150);
                margin-top: rem(20);
            }
        }
        h3 {
            font-size: rem(25);
            @media (max-width: $md) {
                font-size: rem(18);
                margin-top: rem(25);
            }
        }
        &:nth-child(7) {
            background-color: $color-1;
            clip-path: inset(0 -100vmax);
        }
        &:nth-child(5),
        &:nth-child(8) {
            background-color: $color-2;
            color: $color-light;
            box-shadow: 0 0 0 100vmax $color-2;
            clip-path: inset(0 -100vmax);
        }
        &:nth-child(6) {
            background-color: $color-4;
            color: $color-dark;
            box-shadow: 0 0 0 100vmax $color-4;
            clip-path: inset(0 -100vmax);
            h3 {
                color: $color-dark;
            }
            p {
                color: $color-dark;
            }
        }
    }
    &__svg {
        position: absolute;
        right: 0;
        z-index: 2;

        @media (max-width: $md) {
            display: flex;
            bottom: 0;
            svg {
                width: 200px;
                height: 260px;
            }
        }
        @media (max-width: $sm) {
            svg {
                width: 100px;
                height: 160px;
            }
        }
    }
    input {
        display: none;
    }
}
